<script>
//import axios from 'axios';
import serverConfig   from '@/helpers/config';
import moment from 'moment'

import projectTabPV from "@/components/widgets/quality/projectTabPV";

export default {
  name: "qualityControlPlan",
  emits: ["getParent"], 
    props: [
      'projectID',
      'data',
    ],
  setup() {
    moment.locale('it')  
    let tk = 'Bearer '+ localStorage.getItem('tk')
    return { tk };
  },
  data() {
	return {
            UrlServer: serverConfig.EP,
            datastore: this.$store.state,
            genData:{
                country:'',
                zipCode:'',
                city:'',
                address:'',
                address_number:'',
                additional_info_to_address:'',
                cordinate_system:'',
                longitude:'',
                latitude:'',
            },
            openEnel:false,
            openGse:false,
            openServ:false,
            openIA:false,
        }
	},
	computed:{ 
		
	},
	methods:{
        getParent(){
            this.$emit('getParent')
            if (localStorage.getItem("prjTab")) {
                setTimeout(() => {
                document.getElementById(localStorage.getItem('prjTab')).click()
            }, 500);
            }            
        },
        setTab(val){
            this.$cookies.set('ptab',val)
        },      
	},
    components:{
        projectTabPV,
      
    }
};
</script>
<template>
    <h5 class="mb-2 mt-2"></h5>
        <div class="card">
            <div class="card-body">
               
                        <div class="tab-content text-muted">

                            <div class="tab-pane active" id="nav-badge-documents" role="tabpanel">
                                <template v-if="projectID && projectID!=undefined">
                                    <projectTabPV :ID="projectID" :data="data" @getParent="getParent()"/>
                                </template>   
                            </div>
                            
                        </div>
                    </div><!-- end card-body -->
                </div>
</template>