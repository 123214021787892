<script>
import { mapActions } from "vuex";
import gestioneProdottiProgetto from "@/components/widgets/worksites/gestioneProdottiProgetto";

import moment from 'moment'
import serverConfig from '@/helpers/config';

import Swal from "sweetalert2";
import axios from 'axios';


export default {
  name: "projectTabPV",
  emits: ["getParent"],  
  props: [
    'ID',
    'data',
  ],
  setup() {
    moment.locale('it')
    let tk = 'Bearer '+localStorage.getItem('tk')
    return { tk };
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        showProducts:false,
        showRoofPitch:false,
        item:{
            projectDetails:{
                pod:'',
                podAddress:'',
                podAddressNumber:'',
                podAddressCity:'',
                powerFSupplier:'',
                voltageFSupplier: 0.00,
                contractType:'',
                type:'fotovoltaico',
                power: 0.00,
                date: 0.00,
                dateStr:'',
                startStr: '', 
                status:'pending',
                response: '', 
                note: '', 
                bucket: '', 
                cordinate_system: '', 
                longitude: '', 
                latitude: '', 
                FIT: '', 
                registrationDate: '', 
                dateOfLastUpdate: '', 
                responsiblePerson: '', 
                numberOfModules: '', 
                moduleManufacturer: '', 
                inverter: '', 
                inverterCode:'',

                inverterManufacturer: '', 
                inverterNote: '', 
                typeModule: '', 
                chassis: '', 
                dcPower: 0.00,
                acPower: 0.00,
                inverterPower: 0.00,
                inverterWithStorage: 0.00,
                remoteControToDSO: '', 
                connectedToTrasmission: '', 
                typeOfGrid: '', 
                maxPowerFedIntoGrid: 0.00,
                totalPrice: '', 
                vatPerc:22,
                contractCode: '', 
                createdBy: this.$store.state, 
            }
        },
        roofPitch:{        
          totalRP:0,
          rpList:[]
        },
        objRP:{
          row:0,
          number:0,
          panelQty:0,
          latitute:0,
          longitude:0,
          power:0.00,
          roofShapes: '', // Forma del tetto
          roofTilt:0.00,  // inclinazione
          orientation:0.00,    // orientamento
          azimuth:0.00,
          deviationFromMax:0.00,
          note: '',
        },
    };
  },
    validations: {
    },
  mounted() {
    if (this.data.projectDetails){
        
        this.item.projectDetails = this.data.projectDetails
    }
    if (!this.data.roofPitch) {
       
        this.roofPitch.totalRP = 0
    } else {
        this.roofPitch = this.data.roofPitch
    }
    if (!this.data.surveyDetails){
        this.item.surveyDetails.ratedPower = 0
    } else {
        this.item.surveyDetails = this.data.surveyDetails
    } 
    

  },

  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3500,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },

    convertDate(val){
        this.item.date =  moment(val).unix()
        this.updateItem()
    },
    addElement(){
      
      let countL = this.roofPitch.rpList.length
      
      this.roofPitch.rpList.push({
          row:countL+1,
          number:0,
          panelQty:0,
          latitute:0,
          longitude:0,
          power:0.00,
          roofShapes: '', // Forma del tetto
          roofTilt:0.00,  // inclinazione
          orientation:0.00,    // orientamento
          azimuth:0.00,
          deviationFromMax:0.00,
          note: '',
      })
      this.roofPitch.totalRP = this.roofPitch.rpList.length
      this.updateItem()
      this.$emit('getParent')
    },
    removeElement(n){
      let l = this.roofPitch.rpList.filter(function(el){
        return el.row !== n
      })
      this.roofPitch.rpList = l
      this.roofPitch.totalRP = l.length
      //this.regenList()
      this.updateItem()
    },
    updateItem(){
       // this.submitted = true;
        //this.v$.$touch();
        let data = {
          surveyDetails:this.item.surveyDetails,
          projectDetails:this.item.projectDetails,
          roofPitch:this.roofPitch
        }
        this.axiosInterceptor()
        axios.put(`${this.UrlServer}projects/${this.ID}`, data, {headers: { authorization:this.tk}} )
          .then(()=>{
            localStorage.setItem('prjTab','tab-project')
            //this.$emit('getParent')
          })
      },
  },
  components: {
    gestioneProdottiProgetto,
  },

};
</script>

<template>
    <form action="">
      <div class="row mt-25 mb-25">
              <div class="col-md-12 col-lg-12 background-btn-grey">
                <div class="card-header align-items-center d-flex">
                  
                  <h4 class="card-title mb-0 flex-grow-1">               
                    <i class="mdi mdi-24px mdi-account-hard-hat"></i> Manodòpera da impiegare 
                  </h4>
                  <div class="flex-shrink-0">
                    <div
                      class="form-check form-switch form-switch-right form-switch-md"
                    >
                      <label for="FormSelectDefault" class="form-label text-muted"
                        >Mostra</label
                      >
                      <input
                        class="form-check-input code-switcher"
                        type="checkbox"
                        id="FormSelectDefault"
                        v-model="showRoofPitch"
                      />
                    </div>
                  </div>
                </div>
              </div>
        </div>
    <template v-if="roofPitch && showRoofPitch">
        <template v-if="roofPitch.totalRP > 0">
          <div class="row  mt-50 back-white" >
            <div class="col-lg-12 col-md-12 text-center mt-25">
                <h3> Manodòpera</h3>       
            </div>      
          </div>
          <div class="row  back-grey"  v-for="i in roofPitch.rpList" :key="i.row">
                <div class="col-lg-1 col-md-12">
                    <div class="input-group mt-30">
                    Falda {{ i.row }}
                    </div>
                </div>
                <div class="col-lg-2 col-md-12">
                    <label for="roofShapesInput" class="form-label">Tipo di posa telai</label>
                    <div class="input-group">
                        <select class="form-control" :id="'roofShapes_'+ i.row" v-model="i.roofShapes"  @change="updateItem">
                            <option value="complanare">Complanare</option>
                            <option value="rialzo">Rialzo</option>
                        </select>
                    </div>
                </div> 
                <div class="col-lg-2 col-md-12">
                    <label for="orientationInput" class="form-label">Orientamento</label>
                    <div class="input-group">
                        <span class="input-group-text" :id="'inputGroup-sizing-default_'+i.row">°</span>
                            <input type="number" class="form-control width-10" id="orientationInput" v-model="i.orientation"  @blur="updateItem" >
                    </div>
                </div>          
                <div class="col-lg-2 col-md-12">
                    <label for="roofTiltInput" class="form-label">Inclinazione</label>
                    <div class="input-group">
                        <span class="input-group-text" :id="'inputGroup-sizing-default_'+i.row">°</span>
                        <input type="number" class="form-control width-10" id="roofTiltInput" v-model="i.roofTilt"  @blur="updateItem" >
                    </div>            
                </div>
                <div class="col-lg-2 col-md-12">
                    <label for="panelQtyInput" class="form-label">N. Pannelli</label>
                    <div class="input-group">
                        <input type="number" class="form-control width-10" id="panelQtyInput" v-model="i.panelQty"  @blur="updateItem" >
                    </div>
                </div>
                <div class="col-lg-2 col-md-12">
                    <label for="powerInput" class="form-label">Potenza</label>
                    <div class="input-group">
                        <span class="input-group-text" id="inputGroup-sizing-default">Kw</span>
                        <input type="number" class="form-control width-10" id="codeInput" v-model="i.power"  @blur="updateItem" >
                    </div>
                </div>
                <div class="col-lg-1 col-md-12">
                    <div class="input-group mt-30">
                        <b-button variant="danger" class="btn-icon waves-effect waves-light" title="Rimuovi falda" @click="removeElement(i.row)"><i class="ri-delete-bin-5-line"></i></b-button>
                    </div>
                </div>
            </div>
        </template>
    </template>

        <div class="row  back-grey mt-50" v-if="roofPitch && showRoofPitch">
            <div class="col-lg-12 col-md-12 mt-30 text-center">
                <b-button pill variant="primary" class="btn-label waves-effect waves-light" @click="addElement"><i class="ri-add-line label-icon align-middle rounded-pill fs-16 me-2"></i> Aggiungi falda</b-button>              
            </div>
        </div>
        
        <div class="row  mt-50 " v-if="showProducts">
          <div class="col-lg-12 col-md-12 ">
            <gestioneProdottiProgetto type="project" :projectID="data._id" :data="data"  />  
          </div>      
        </div>
<!-- Mezzi ed attrezzatura -->
<div class="row mt-25 mb-25">
              <div class="col-md-12 col-lg-12 background-btn-grey">
                <div class="card-header align-items-center d-flex">
                 
                  <h4 class="card-title mb-0 flex-grow-1">              
                    <i class="mdi mdi-24px mdi-car-lifted-pickup"></i>  Mezzi ed attrezzature da impiegare 
                  </h4>
                  <div class="flex-shrink-0">
                    <div
                      class="form-check form-switch form-switch-right form-switch-md"
                    >
                      <label for="FormSelectDefault" class="form-label text-muted"
                        >Mostra</label
                      >
                      <input
                        class="form-check-input code-switcher"
                        type="checkbox"
                        id="FormSelectDefault"
                        v-model="showRoofPitch"
                      />
                    </div>
                  </div>
                </div>
              </div>
        </div>
    <template v-if="roofPitch && showRoofPitch">
        <template v-if="roofPitch.totalRP > 0">
          <div class="row  mt-50 back-white" >
            <div class="col-lg-12 col-md-12 text-center mt-25">
                <h3>Manodòpera</h3>       
            </div>      
          </div>
          <div class="row  back-grey"  v-for="i in roofPitch.rpList" :key="i.row">
                <div class="col-lg-1 col-md-12">
                    <div class="input-group mt-30">
                    Falda {{ i.row }}
                    </div>
                </div>
                <div class="col-lg-2 col-md-12">
                    <label for="roofShapesInput" class="form-label">Tipo di posa telai</label>
                    <div class="input-group">
                        <select class="form-control" :id="'roofShapes_'+ i.row" v-model="i.roofShapes"  @change="updateItem">
                            <option value="complanare">Complanare</option>
                            <option value="rialzo">Rialzo</option>
                        </select>
                    </div>
                </div> 
                <div class="col-lg-2 col-md-12">
                    <label for="orientationInput" class="form-label">Orientamento</label>
                    <div class="input-group">
                        <span class="input-group-text" :id="'inputGroup-sizing-default_'+i.row">°</span>
                            <input type="number" class="form-control width-10" id="orientationInput" v-model="i.orientation"  @blur="updateItem" >
                    </div>
                </div>          
                <div class="col-lg-2 col-md-12">
                    <label for="roofTiltInput" class="form-label">Inclinazione</label>
                    <div class="input-group">
                        <span class="input-group-text" :id="'inputGroup-sizing-default_'+i.row">°</span>
                        <input type="number" class="form-control width-10" id="roofTiltInput" v-model="i.roofTilt"  @blur="updateItem" >
                    </div>            
                </div>
                <div class="col-lg-2 col-md-12">
                    <label for="panelQtyInput" class="form-label">N. Pannelli</label>
                    <div class="input-group">
                        <input type="number" class="form-control width-10" id="panelQtyInput" v-model="i.panelQty"  @blur="updateItem" >
                    </div>
                </div>
                <div class="col-lg-2 col-md-12">
                    <label for="powerInput" class="form-label">Potenza</label>
                    <div class="input-group">
                        <span class="input-group-text" id="inputGroup-sizing-default">Kw</span>
                        <input type="number" class="form-control width-10" id="codeInput" v-model="i.power"  @blur="updateItem" >
                    </div>
                </div>
                <div class="col-lg-1 col-md-12">
                    <div class="input-group mt-30">
                        <b-button variant="danger" class="btn-icon waves-effect waves-light" title="Rimuovi falda" @click="removeElement(i.row)"><i class="ri-delete-bin-5-line"></i></b-button>
                    </div>
                </div>
            </div>
        </template>
    </template>

        <div class="row  back-grey mt-50" v-if="roofPitch && showRoofPitch">
            <div class="col-lg-12 col-md-12 mt-30 text-center">
                <b-button pill variant="primary" class="btn-label waves-effect waves-light" @click="addElement"><i class="ri-add-line label-icon align-middle rounded-pill fs-16 me-2"></i> Aggiungi falda</b-button>              
            </div>
        </div>
        
        <div class="row  mt-50 " v-if="showProducts">
          <div class="col-lg-12 col-md-12 ">
            <gestioneProdottiProgetto type="project" :projectID="data._id" :data="data"  />  
          </div>      
        </div>
<!-- / Mezzi ed attrezzatura -->
        <div class="row mt-25 mb-25">
              <div class="col-md-12 col-lg-12 background-btn-grey">
                <div class="card-header align-items-center d-flex">
                  
                  <h4 class="card-title mb-0 flex-grow-1">               
                    <i class="mdi mdi-24px mdi-basket-plus-outline"></i>  Approvvigionamento materiali-servizi
                  </h4>
                  <div class="flex-shrink-0">
                    <div
                      class="form-check form-switch form-switch-right form-switch-md"
                    >
                      <button type="button" class="btn btn-secondary" title="Apri scheda di lavorazione" @click="$router.push('/projects/detail/supplies-products/'+$route.params.id)"> <i class="bx bx-edit-alt"></i></button>
                      &nbsp;
                      <label for="FormSelectDefault" class="form-label text-muted"
                        >Mostra</label
                      >
                      <input
                        class="form-check-input code-switcher"
                        type="checkbox"
                        id="FormSelectDefault"
                        v-model="showProducts"
                      /> 
                    </div>
                  </div>
                </div>
              </div>
        </div>



        
    </form>




</template>
